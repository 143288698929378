import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from './styles';
import { imgs } from '~/assets/Banner_desk_topo';
import setaPrev from '~/assets/icons/seta-prev.gif';
import setaNext from '~/assets/icons/seta-next.gif';
import logo from '~/assets/logo_header_neg-2x.png';

function Carousel() {
  let pos = 0;

  const slideInfinito = window.setInterval(function carrossel() {
    // Inicia a contagem de 7 segundos
    let currentPos = pos;
    const nextPos = pos + 1;
    if (
      nextPos <
      Array.from(document.querySelectorAll('.conheca')).length - 1
    ) {
      document
        .querySelectorAll('.conheca')
        [currentPos].classList.toggle('fadein');
      document.querySelectorAll('.conheca')[nextPos].classList.toggle('fadein');
      pos += 1;
      currentPos = pos;
      document.querySelector(
        '.banner-topo'
      ).style.backgroundImage = `url(${imgs[currentPos]})`;
    } else {
      document
        .querySelectorAll('.conheca')
        [currentPos].classList.toggle('fadein');
      pos = 0;
      currentPos = 0;
      document
        .querySelectorAll('.conheca')
        [currentPos].classList.toggle('fadein');
      document.querySelector(
        '.banner-topo'
      ).style.backgroundImage = `url(${imgs[currentPos]})`;
    }
  }, 7000);

  function nextBannerTopo() {
    let currentPos = pos;
    const nextPos = pos + 1;
    if (
      nextPos <
      Array.from(document.querySelectorAll('.conheca')).length - 1
    ) {
      document
        .querySelectorAll('.conheca')
        [currentPos].classList.toggle('fadein');
      document.querySelectorAll('.conheca')[nextPos].classList.toggle('fadein');
      pos += 1;
      currentPos = pos;
      document.querySelector(
        '.banner-topo'
      ).style.backgroundImage = `url(${imgs[currentPos]})`;
    } else {
      document
        .querySelectorAll('.conheca')
        [currentPos].classList.toggle('fadein');
      pos = 0;
      currentPos = 0;
      document
        .querySelectorAll('.conheca')
        [currentPos].classList.toggle('fadein');
      document.querySelector(
        '.banner-topo'
      ).style.backgroundImage = `url(${imgs[currentPos]})`;
    }
    setInterval(slideInfinito, 1000);
  }

  function prevBannerTopo() {
    if (pos - 1 >= 0) {
      document.querySelectorAll('.conheca')[pos].classList.toggle('fadein');
      document.querySelectorAll('.conheca')[pos - 1].classList.toggle('fadein');
      pos -= 1;
      document.querySelector(
        '.banner-topo'
      ).style.backgroundImage = `url(${imgs[pos]})`;
    } else {
      pos = Array.from(document.querySelectorAll('.conheca')).length - 2;
      document.querySelectorAll('.conheca')[0].classList.toggle('fadein');
      document.querySelectorAll('.conheca')[pos].classList.toggle('fadein');
      document.querySelector(
        '.banner-topo'
      ).style.backgroundImage = `url(${imgs[pos]})`;
    }
  }

  return (
    <>
      <Container>
        <div className="banner-topo d-flex align-items-center">
          <div className="container d-none conheca fadein">
            <button
              type="button"
              className="seta-prev my-auto "
              onClick={prevBannerTopo}
            >
              <img src={setaPrev} alt="Ícone seta anterior" />
            </button>
            <div className=" ml-5 pl-5 mr-auto" />
            <button
              type="button"
              className="seta-next my-auto"
              onClick={nextBannerTopo}
            >
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div>
          <div className="container d-none conheca">
            <button
              type="button"
              className="seta-prev my-auto "
              onClick={prevBannerTopo}
            >
              <img src={setaPrev} alt="Ícone seta anterior" />
            </button>
            <div className=" ml-5 pl-5 mr-auto">
              <h1 className="font-size-4">BIONDINA</h1>
              <p className="produto-topo-desc mb-5 legenda-banner">
                Clareia os fios e acentua a cor do loiro, <br />
                deixando as madeixas com mais brilho
              </p>
              <Link
                to={`${process.env.PUBLIC_URL}/biondina`}
                type="submit"
                className="btn-topo"
              >
                EU QUERO!
              </Link>
              <br />
              <img src={logo} alt="Logo Anaconda" className="logo mt-4" />
            </div>
            <button
              type="button"
              className="seta-next my-auto"
              onClick={nextBannerTopo}
            >
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div>
          <div className="container d-none conheca">
            <button
              type="button"
              className="seta-prev my-auto "
              onClick={prevBannerTopo}
            >
              <img src={setaPrev} alt="Ícone seta anterior" />
            </button>
            <div className=" ml-5 pl-5 mr-auto">
              <h2 className="font-size-4">PROFISSIONAL</h2>
              <p className="produto-topo-desc mb-5 legenda-banner">
                Conheça nossa linha de produtos profissionais
              </p>
              <Link
                to={`${process.env.PUBLIC_URL}/produtos-para-cabelo`}
                type="submit"
                className="btn-topo"
              >
                EU QUERO!
              </Link>
              <br />
              <img src={logo} alt="Logo Anaconda" className="logo mt-4" />
            </div>

            <button
              type="button"
              className="seta-next my-auto"
              onClick={nextBannerTopo}
            >
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div>
          <div className="container d-none conheca">
            <button
              type="button"
              className="seta-prev my-auto "
              onClick={prevBannerTopo}
            >
              <img src={setaPrev} alt="Ícone seta anterior" />
            </button>
            <div className=" ml-5 pl-5 mr-auto">
              <h2 className="font-size-4">RETOK</h2>
              <p className="produto-topo-desc mb-5 legenda-banner">
                Conheça nossa linha de <br />
                produtos para cabelos
              </p>
              <Link
                to={`${process.env.PUBLIC_URL}/retok`}
                type="submit"
                className="btn-topo"
              >
                EU QUERO!
              </Link>
              <br />
              <img src={logo} alt="Logo Anaconda" className="logo mt-4" />
            </div>

            <button
              type="button"
              className="seta-next my-auto"
              onClick={nextBannerTopo}
            >
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div>
          <div className="container d-none conheca">
            <button
              type="button"
              className="seta-prev my-auto "
              onClick={prevBannerTopo}
            >
              <img src={setaPrev} alt="Ícone seta anterior" />
            </button>
            <div className=" ml-5 pl-5 mr-auto">
              <h2 className="font-size-4">DermoGLAM</h2>
              <p className="produto-topo-desc mb-5 legenda-banner">
                Conheça nossa linha anti-age
              </p>
              <Link
                to={`${process.env.PUBLIC_URL}/dermoglam`}
                type="submit"
                className="btn-topo"
              >
                EU QUERO!
              </Link>
              <br />
              <img src={logo} alt="Logo Anaconda" className="logo mt-4" />
            </div>

            <button
              type="button"
              className="seta-next my-auto"
              onClick={nextBannerTopo}
            >
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div>
          <div className="container d-none conheca">
            <button
              type="button"
              className="seta-prev my-auto "
              onClick={prevBannerTopo}
            >
              <img src={setaPrev} alt="Ícone seta anterior" />
            </button>
            <div className=" ml-5 pl-5 mr-auto" id="makeup">
              <h2 className="font-size-4">MAKEUP</h2>
              <p className="produto-topo-desc mb-5 legenda-banner">
                Conheça nossa linha de maquiagem
              </p>
              <Link
                to={`${process.env.PUBLIC_URL}/maquiagem`}
                type="submit"
                className="btn-topo"
                id="btn-makeup"
              >
                EU QUERO!
              </Link>
              <br />
              <img src={logo} alt="Logo Anaconda" className="logo mt-4" />
            </div>

            <button
              type="button"
              className="seta-next my-auto"
              onClick={nextBannerTopo}
            >
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div>
          <div className="container d-none conheca ">
            <button
              type="button"
              className="seta-prev my-auto "
              onClick={prevBannerTopo}
            >
              <img src={setaPrev} alt="Ícone seta anterior" />
            </button>
            <div className=" ml-5 pl-5 mr-auto" id="skinCare">
              <h2 className="font-size-4">SKIN CARE</h2>
              <p className="produto-topo-desc mb-5 legenda-banner">
                Conheça nossa linha de <br />
                produtos para o rosto
              </p>
              <Link
                to={`${process.env.PUBLIC_URL}/clareador-facial`}
                type="submit"
                className="btn-topo"
                id="btn-skinCare"
              >
                EU QUERO!
              </Link>
              <br />
              <img src={logo} alt="Logo Anaconda" className="logo mt-4" />
            </div>

            <button
              type="button"
              className="seta-next my-auto"
              onClick={nextBannerTopo}
            >
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div>
        </div>
      </Container>
    </>
  );
}

export default Carousel;
