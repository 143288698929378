import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from './styles';
import Footer from '~/components/Footer';
import LocalCatalogo from '~/components/LocalCatalogo';
import BannerCatalogo from '~/components/BannerCatalogo';
import { imgs } from '~/assets/maquiagem';
import setaPrev from '~/assets/icons/seta-prev.gif';
import setaNext from '~/assets/icons/seta-next.gif';
import Matte from '~/pages/Maquiagem/matte.pdf';
import Hidratante from '~/pages/Maquiagem/hidratante.pdf';

function Maquiagem() {
  let pos = 0;

  function next() {
    if (pos + 1 < Array.from(document.querySelectorAll('.info-prod')).length) {
      const width = document.querySelector('.infos').offsetWidth;
      document.querySelector('.infos').scrollLeft += width;
      pos += 1;
      document.querySelector('.seta-prev').style.visibility = 'visible';
      if (
        pos ===
        Array.from(document.querySelectorAll('.info-prod')).length - 1
      ) {
        document.querySelector('.seta-next').style.visibility = 'hidden';
      } else {
        document.querySelector('.seta-prev').style.visibility = 'visible';
      }
    }
  }

  function prev() {
    if (pos - 1 >= 0) {
      const width = document.querySelector('.infos').offsetWidth;
      document.querySelector('.infos').scrollLeft -= width;
      pos -= 1;
      document.querySelector('.seta-next').style.visibility = 'visible';
      if (pos === 0) {
        document.querySelector('.seta-prev').style.visibility = 'hidden';
      } else {
        document.querySelector('.seta-prev').style.visibility = 'visible';
      }
    }
  }
  return (
    <>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Anaconda Cosméticos – Maquiagem</title>
          <meta
            name="description"
            content="Conheça os produtos de maquiagem da Anaconda Cosméticos. Uma linha com batons, rímel, delineador, blush e muito mais!"
          />
        </Helmet>
        <div className="row-100 fundo-destaque">
          <div className="container h-100 d-flex">
            <div className="ml-5 mobile-ml-1 my-auto">
              <h2 className="h1 text-uppercase maquiagem">Maquiagem</h2>
              <p className="desc-produto ">
                Conheça os produtos de maquiagem da Anaconda Cosméticos. Uma
                linha com batons, rímel, delineador, blush e muito mais!
              </p>
            </div>
          </div>
        </div>
        <div className="row-100 ">
          <div className="container border bg-white mt-n5 px-4 py-4">
            <h1 className="h2 font-weight-bold text-center py-5 m-auto frase-principal">
              A maquiagem perfeita para realçar a sua beleza
            </h1>
          </div>
        </div>
        <div className="row-100 fundo-tarja">
          <div className="container d-flex h-100">
            <h2 className="h6 w-50 text-uppercase text-center m-auto">
              Produtos de alta fixação desenvolvidos para uma pele bonita o dia
              todo
            </h2>
          </div>
        </div>

        <div className="row-100 bg-white border-left border-right border-bottom py-5 mobile-row">
          <div className="container d-flex">
            <button type="button" className="seta-prev my-auto " onClick={prev}>
              <img src={setaPrev} alt="Ícone seta anterior" />
            </button>

            <div className="row align-items-center infos">
              <div className="info-prod row px-5">
                <h3 className="h4 font-weight-bold text-center">
                  Lábios poderosos pedem alta fixação e hidratação
                </h3>
                <p className="text-center">
                  Os produtos de maquiagem para lábios da Anaconda são um
                  sucesso em todo lugar. Com a alta fixação e hidratação labial,
                  as cores variam desde as mais vibrantes até os tons de nude
                  para atender aos mais variados gostos, sempre realçando a
                  beleza com lábios poderosos.
                </p>
                <div className="container d-contents py-4">
                  <div className="col-4 d-flex align-items-center p-2 mobile-col-6">
                    <div>
                      <h3 className="h5 conheca-linha font-weight-bold">
                        Conheça a <br />
                        linha labial
                      </h3>
                      <ul>
                        <li>• Batons.</li>
                        <li>• Cristal Gloss.</li>
                        <li>• Batom Líquido.</li>
                        <li>• Lip Tint.</li>
                        <li>• Manteiga de Cacau.</li>
                        <li className="mt-2">
                          {' '}
                          <span className="font-weight-bold">
                            Linha de batons
                          </span>
                          <ul>
                            <li>
                              •{' '}
                              <a
                                className="text-blue"
                                href={Matte}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <u>Tonalidade Matte</u>
                              </a>
                            </li>
                            <li>
                              •
                              <a
                                className="text-blue"
                                href={Hidratante}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {' '}
                                <u>Tonalidade Hidratante.</u>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-4 p-2 mobile-col-6">
                    <img src={imgs.batom[0]} alt="Batom" />
                  </div>

                  <div className="col-4 p-2 mobile-col-6">
                    <img src={imgs.batom[1]} alt="Batom Líquido" />
                  </div>

                  <div className="col-4 p-2 mobile-col-6">
                    <img src={imgs.batom[2]} alt="Lip Tint" />
                  </div>
                  <div className="col-4 p-2 mobile-col-6">
                    <img src={imgs.batom[3]} alt="Cristal Gloss" />
                  </div>

                  <div className="col-4 p-2 mobile-col-6">
                    <img src={imgs.batom[4]} alt="Manteiga de Cacau" />
                  </div>
                </div>
              </div>

              <div className="info-prod row px-5">
                <h3 className="h4 font-weight-bold text-center">
                  Pele sedosa e acabamento natural para uma maquiagem impecável
                </h3>
                <p className="text-center">
                  A Anaconda desenvolveu uma linha completa de produtos para
                  maquiagem facial. Sempre prezando pela qualidade, os produtos
                  possuem textura sedosa, alta capacidade de correção de manchas
                  e imperfeições da pele, efeito natural e acetinado e ativos
                  para alta fixação.
                </p>

                <div className="container d-contents py-4">
                  <div className="col-4 d-flex align-items-center p-2 mobile-col-6">
                    <div>
                      <h3 className="h5 conheca-linha font-weight-bold">
                        Conheça as makes <br /> para face
                      </h3>
                      <ul>
                        <li>• Blush.</li>
                        <li>• Pó compacto.</li>
                        <li>• Pó facial.</li>
                        <li>• Corretivo Retok Facial.</li>
                        <li>• Iluminador líquido.</li>
                        <li>• Base líquida.</li>
                        <li>• CC Cream.</li>
                        <li>• Bruma Fixadora de maquiagem.</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-4 p-2 mobile-col-6">
                    <img src={imgs.pele[0]} alt="Corretivo Retok Facial" />
                  </div>

                  <div className="col-4 p-2 mobile-col-6">
                    <img src={imgs.pele[1]} alt="Pó compacto" />
                  </div>

                  <div className="col-4 p-2 mobile-col-6">
                    <img src={imgs.pele[2]} alt="Base líquida" />
                  </div>
                  <div className="col-4 p-2 mobile-col-6">
                    <img src={imgs.pele[3]} alt="Pó Facial" />
                  </div>

                  <div className="col-4 p-2 mobile-col-6">
                    <img src={imgs.pele[4]} alt="Iluminador Líquido" />
                  </div>

                  <div className="col-4 p-2 mobile-col-6">
                    <img src={imgs.pele[5]} alt="Blush" />
                  </div>
                </div>
              </div>
              <div className="info-prod row px-5">
                <h3 className="h4 font-weight-bold text-center">
                  Produtos para um olhar poderoso
                </h3>
                <p className="text-center">
                  A Anaconda desenvolveu produtos de maquiagem para os olhos com
                  a finalidade de realçar o olhar, dando mais poder e
                  luminosidade, proporcionando um efeito glamoroso para o olhar.
                </p>
                <div className="container d-contents py-4">
                  <div className="col-4 d-flex align-items-center p-2 mobile-col-6">
                    <div>
                      <h3 className="h5 conheca-linha font-weight-bold">
                        Conheça a linha de
                        <br /> maquiagem para olhos
                      </h3>

                      <ul>
                        <li>• Máscara para cílios.</li>
                        <li>• Sombra Maxibrilho.</li>
                        <li>• Fixador de Sombra.</li>
                        <li>• Lápis delineador retrátil.</li>
                        <li>• Caneta delineadora.</li>
                        <li>• Lápis delineador de olhos.</li>
                        <li>• Maximum Volume Big Eyelashes.</li>
                        <li>• Lápis sombra iluminadora.</li>
                        <li>• Paleta de sombras.</li>
                        <li>• Delineador color.</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-4 p-2 mobile-col-6">
                    <img src={imgs.olhar[0]} alt="Máscara para cílios" />
                  </div>

                  <div className="col-4 p-2 mobile-col-6">
                    <img src={imgs.olhar[1]} alt="Delineador Líquido" />
                  </div>

                  <div className="col-4 p-2 mobile-col-6">
                    <img src={imgs.olhar[2]} alt="Lápis delineador de olhos" />
                  </div>
                  <div className="col-4 p-2 mobile-col-6">
                    <img src={imgs.olhar[3]} alt="Caneta delineadora" />
                  </div>

                  <div className="col-4 p-2 mobile-col-6">
                    <img src={imgs.olhar[4]} alt="Paleta de sombras" />
                  </div>
                </div>
              </div>
              <div className="info-prod row px-5">
                <h3 className="h4 font-weight-bold text-center">
                  A moldura perfeita dos olhos
                </h3>
                <p className="text-center">
                  A Anaconda desenvolveu uma linha de produtos para sobrancelhas
                  perfeitas. As sobrancelhas são consideradas as molduras dos
                  olhos e quando bem-feitas valorizam a beleza e a
                  personalidade.
                </p>
                <div className="container d-contents py-4">
                  <div className="col-4 d-flex align-items-center p-2 mobile-col-12">
                    <div>
                      <h3 className="h5 conheca-linha font-weight-bold">
                        Conheça a linha de
                        <br /> produtos para
                        <br /> sobrancelhas
                      </h3>
                      <ul>
                        <li>• Lápis para Sobrancelha.</li>
                        <li>• Caneta para Sobrancelha Easy Linner.</li>
                        <li>• Retok Sobrancelhas Líquido.</li>
                        <li>• Duo Perfect Browns.</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-4 p-2 mobile-col-6">
                    <img
                      src={imgs.sobrancelha[0]}
                      alt="Retok Sobrancelhas Líquido"
                    />
                  </div>

                  <div className="col-4 p-2 mobile-col-6">
                    <img
                      src={imgs.sobrancelha[2]}
                      alt="Lápis para Sobrancelha"
                    />
                  </div>
                  <div className="col-4 p-2 mobile-col-6 ml-md-auto">
                    <img src={imgs.sobrancelha[3]} alt="Duo Perfect Browns" />
                  </div>

                  <div className="col-4 p-2 mobile-col-6">
                    <img
                      src={imgs.sobrancelha[4]}
                      alt="Caneta para Sobrancelha Easy Linner"
                    />
                  </div>
                </div>
              </div>
            </div>

            <button type="button" className="seta-next my-auto" onClick={next}>
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div>
        </div>
      </Container>
      <BannerCatalogo />
      <LocalCatalogo />
      <Footer />
    </>
  );
}

export default Maquiagem;
