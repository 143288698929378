import React from 'react';
import { Router } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Routes from './routes';
import history from './services/history';
import favicon from '~/assets/icons/favicon.ico';
import ScrollToTop from '~/components/ScrollToTop';

import Header from '~/components/Header';

import 'bootstrap/dist/css/bootstrap.min.css';

import GlobalStyle from './styles/global';

function App() {
  return (
    <>
      <Router basename="http://anacondacosmeticos.com.br/" history={history}>
        <Helmet>
          <link rel="shortcut icon" type="image/x-icon" href={favicon} />

        </Helmet>
        <ScrollToTop>
          <GlobalStyle />
          <Header />
          <Routes />
        </ScrollToTop>
      </Router>
      <Router basename="/onde-encontrar" history={history}>
        <Helmet>
          <link rel="shortcut icon" type="image/x-icon" href={favicon} />

        </Helmet>
        <ScrollToTop>
          <GlobalStyle />
          <Header />
          <Routes />
        </ScrollToTop>
      </Router>
    </>
  );
}

export default App;
